/* eslint-disable no-unused-vars */
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REQUEST_API_PERMISSIONS,
} from '../actions';
import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  selectPermissions,
} from './actions';
import {
  adminRoot,
  currentUser,
  servicePath,
} from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';

const searchPermission = async (params) =>
  axios
    .get(`${servicePath}/roles?user=${params.user}`, {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
function* getApiPermission(params) {
  const request = params.payload;
  try {
    const data = yield call(searchPermission, request);
    yield put(selectPermissions({ data, params }));
  } catch (e) {
    console.log(e);
  }
}
export function* watchPermissions() {
  yield takeLatest(REQUEST_API_PERMISSIONS, getApiPermission);
}
export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}
const loginWithStrapi = async (email, password) =>
  axios
    .post(`${servicePath}/auth/local`, {
      identifier: email,
      password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
const apiCondominiums = `${servicePath}/condominiums`;
const userCondominiums = async (idUser, token) =>
  axios
    .get(`${apiCondominiums}?admin=${idUser}&_sort=id`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return undefined;
    });
function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithStrapi, email, password);
    if (!loginUser.message) {
      const loginCondominiums = yield call(
        userCondominiums,
        loginUser.user.staffItem?.id,
        loginUser.jwt
      );
      if (loginCondominiums !== undefined && loginCondominiums.length) {
        const item = {
          ...loginUser.user,
          condominiums: loginCondominiums,
        };
        yield put(loginUserSuccess(item, loginUser.jwt));
        history.push(adminRoot);
      } else {
        yield put(
          loginUserSuccess(
            { ...loginUser.user, condominiums: null },
            loginUser.jwt
          )
        );
        if (loginUser.user.role?.id === 5) {
          history.push(adminRoot);
        } else {
          history.push('/user/first-condominium');
        }
      }
    } else {
      switch (loginUser.response?.data?.data[0]?.messages[0]?.id) {
        case 'Auth.form.error.confirmed':
          yield put(
            loginUserError(
              'La cuenta aún no esta verificada, revisa si te llego el correo de confirmación o inténte de nuevo.'
            )
          );
          break;
        case 'Auth.form.error.invalid':
          yield put(loginUserError('Error en el usuario o contraseña.'));
          break;
        default:
          yield put(loginUserError('Por favor, inténte de nuevo más tarde.'));
          break;
      }
    }
  } catch (error) {
    yield put(loginUserError('Por favor, inténte de nuevo más tarde.'));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (data) =>
  axios
    .post(`${servicePath}/auth/local/register`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

const registerStaff = async (data) =>
  axios
    .post(`${servicePath}/staff-items`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

function* registerWithEmailPassword({ payload }) {
  const { user } = payload;
  try {
    const registerUser = yield call(registerWithEmailPasswordAsync, {
      ...user,
      username: `${user.name} ${user.lastname}`,
    });
    if (!registerUser.message) {
      const values = {
        ...user,
        user: registerUser.user.id,
      };
      const registerStaffItem = yield call(registerStaff, values);
      if (registerUser.user.id && !registerStaffItem.message) {
        const item = { id: registerUser.user.id, ...currentUser };
        setCurrentUser(item);
        yield put(registerUserSuccess(item));
      } else {
        yield put(registerUserError('staff'));
      }
    } else {
      yield put(registerUserError('user'));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) =>
  axios
    .post(`${servicePath}/auth/forgot-password`, {
      email,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });

function* forgotPassword({ payload }) {
  const { forgotUserMail } = payload;
  try {
    const forgotPasswordStatus = yield call(
      forgotPasswordAsync,
      forgotUserMail
    );
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (code, newPassword, newPasswordConfirm) =>
  axios
    .post(`${servicePath}/auth/reset-password`, {
      code,
      password: newPassword,
      passwordConfirmation: newPasswordConfirm,
    })
    .then(async (res) => {
      await axios.post(
        `${servicePath}/notifications`,
        {
          title: 'Cambio de contraseña',
          message: `Contraseña cambiada con éxito`,
          user: res.data.user.id,
          viewed: false,
          url: `account-general`,
          type: 'profile',
          date: new Date(),
        },
        {
          headers: {
            Authorization: `Bearer ${res.data.jwt}`,
          },
        }
      );
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });

function* resetPassword({ payload }) {
  const { code, newPassword, newPasswordConfirm } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      code,
      newPassword,
      newPasswordConfirm
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchPermissions),
  ]);
}
