import { getCurrentLanguage, getCurrentCondominium } from 'helpers/Utils';
import {
  CHANGE_LOCALE,
  CHANGE_CONDOMINIUM,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ADD_USER_IMAGE,
  ADD_USER_IMAGE_SUCCESS,
  ADD_USER_IMAGE_ERROR,
  DELETE_USER_IMAGE,
  DELETE_USER_IMAGE_SUCCESS,
  DELETE_USER_IMAGE_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
} from '../actions';
import { getCurrentUser } from '../../helpers/Utils';
// import { isAuthGuardActive, currentUser } from '../../constants/defaultValues';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  condominium: getCurrentCondominium(),
  currentUser: getCurrentUser(),
  forgotUserMail: '',
  newPassword: '',
  code: '',
  loading: false,
  strapiToken: '',
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case CHANGE_CONDOMINIUM:
      return { ...state, condominium: action.payload };
    case UPDATE_USER:
      return { ...state, loading: true, error: '' };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload.user,
        error: '',
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case ADD_USER_IMAGE:
      return { ...state, loading: true, error: '' };
    case ADD_USER_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload.user,
        error: '',
      };
    case ADD_USER_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case DELETE_USER_IMAGE:
      return { ...state, loading: true, error: '' };
    case DELETE_USER_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload.user,
        error: '',
      };
    case DELETE_USER_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case UPDATE_EMAIL:
      return { ...state, loading: true, error: '' };
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload.user,
        error: '',
      };
    case UPDATE_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case UPDATE_PASSWORD:
      return { ...state, loading: true, error: '' };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload.user,
        error: '',
      };
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
