/* eslint-disable no-unused-vars */
import axios from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { UPDATE_USER, ADD_USER_IMAGE, DELETE_USER_IMAGE } from '../actions';
import {
  updateUserSuccess,
  updateUserError,
  addUserImageSuccess,
  addUserImageError,
  deleteUserImageSuccess,
  deleteUserImageError,
} from './actions';
import { getCurrentUser, setCurrentUser } from '../../helpers/Utils';
import {
  // adminRoot,
  currentUser as defaultCurrentUser,
  servicePath,
} from '../../constants/defaultValues';

const apiCondominiums = `${servicePath}/condominiums`;
const getCondominiums = async (idUser, token) =>
  axios
    .get(`${apiCondominiums}?admin=${idUser}&_sort=id`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return undefined;
    });

export function* watchUpdateUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_USER, updateUsernameAndPhone);
}

const updateUserPhoneAsync = async (data) =>
  axios
    .put(
      `${servicePath}/users/${data.authUser.currentUser.id}`,
      {
        phone: data.newValues.phone,
      },
      {
        headers: {
          Authorization: `Bearer ${data.authUser.strapiToken}`,
        },
      }
    )
    .then((res) => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return undefined;
    });

const updateNameAndLastnameAsync = async (data) =>
  axios
    .put(
      `${servicePath}/staff-items/${data.authUser.currentUser.staffItem.id}`,
      {
        name: data.newValues.name,
        lastname: data.newValues.lastname,
      },
      {
        headers: {
          Authorization: `Bearer ${data.authUser.strapiToken}`,
        },
      }
    )
    .then((res) => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return undefined;
    });

function* updateUsernameAndPhone({ payload }) {
  const { newValues, authUser } = payload;
  // console.log(payload);

  // const { history } = payload;
  try {
    const updateStaffItem = yield call(updateNameAndLastnameAsync, {
      newValues,
      authUser,
    });
    // console.log('updateStaffItem', updateStaffItem);
    if (updateStaffItem) {
      // const newValues = {
      //   ...user,
      //   name: user.name,
      //   lastname: user.lastname,
      //   phone: user.phone,
      // };
    } else {
      yield put(updateUserError('No se a podido actualizar el usuario'));
    }

    if (newValues.phone) {
      const updatePhone = yield call(updateUserPhoneAsync, {
        newValues,
        authUser,
      });
      const itemsPhone = {
        ...authUser.currentUser,
        phone: updatePhone,
        staffItem: updateStaffItem,
      };
    } else {
      const items = {
        ...authUser.currentUser,
        staffItem: updateStaffItem,
      };
      yield put(updateUserSuccess(items));
    }
  } catch (error) {
    yield put(updateUserError(error));
  }
}

export function* watchAddUserImage() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(ADD_USER_IMAGE, addUserImage);
}

const addUserImageAsync = async (data) =>
  axios
    .post(`${servicePath}/upload`, data.imageFile, {
      headers: {
        Authorization: `Bearer ${data.strapiToken}`,
      },
    })
    .then((res) => {
      const imageId = res.data[0].id;
      axios
        .put(
          `${servicePath}/staff-items/${data.staffItem}`,
          {
            image: imageId,
          },
          {
            headers: {
              Authorization: `Bearer ${data.strapiToken}`,
            },
          }
        )
        .then((resp) => {
          // console.log(data.oldImage, data);
          if (data.oldImage !== undefined) {
            axios.delete(
              `${servicePath}/upload/files/${data.oldImage}`,
              {
                image: imageId,
              },
              {
                headers: {
                  Authorization: `Bearer ${data.strapiToken}`,
                },
              }
            );
          }
          return resp.data;
        })
        .catch((err) => {
          return undefined;
        });
      return res.data;
    })
    .catch((err) => {
      return undefined;
    });

function* addUserImage({ payload }) {
  const { imageFile, authUser, oldImage } = payload;
  // console.log('oldImage: ', payload);

  try {
    const addUserImageRes = yield call(addUserImageAsync, {
      imageFile,
      staffItem: authUser.currentUser.staffItem.id,
      strapiToken: authUser.strapiToken,
      oldImage,
    });
    if (addUserImageRes) {
      const user = {
        ...authUser.currentUser,
        staffItem: {
          ...authUser.currentUser.staffItem,
          image: addUserImageRes[0],
        },
      };
      // console.log('user', user);
      yield put(addUserImageSuccess(user));
      // const updateAccount = yield call(updateUserAsync, newValues);
      // if (updateAccount.user.id) {
      //   const item = { id: updateAccount.user.id, ...defaultCurrentUser };
      //   setCurrentUser(item);
      //   yield put(updateUserSuccess(item));
      //   //  history.push(adminRoot);
      // } else {
      //   yield put(updateUserError('No se a podido actualizar el usuario'));
      // }
    } else {
      yield put(addUserImageError('Error al subir la imagen'));
    }
  } catch (error) {
    yield put(addUserImageError(error));
  }
}

export function* watchDeleteUserImage() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_USER_IMAGE, deleteUserImage);
}

const deleteUserImageAsync = async (data) =>
  axios
    .put(
      `${servicePath}/staff-items/${data.currentUser.staffItem.id}`,
      {
        image: null,
      },
      {
        headers: {
          Authorization: `Bearer ${data.strapiToken}`,
        },
      }
    )
    .then((res) => {
      console.log(res);
      axios
        .delete(
          `${servicePath}/upload/files/${data.urrentUser.staffItem.image.id}`
        )
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });

function* deleteUserImage({ payload }) {
  const { currentUser, strapiToken } = payload;

  try {
    const deleteUserImageRes = yield call(deleteUserImageAsync, {
      currentUser,
      strapiToken,
    });
    console.log(getCurrentUser());
    if (!deleteUserImageRes.message) {
      // const newValues = {
      //   ...user,
      //   name: user.name,
      //   lastname: user.lastname,
      //   phone: user.phone,
      // };
      const item = { id: currentUser.id, ...defaultCurrentUser };
      setCurrentUser(item);
      // const updateAccount = yield call(updateUserAsync, newValues);
      // if (updateAccount.user.id) {
      //   const item = { id: updateAccount.user.id, ...defaultCurrentUser };
      //   setCurrentUser(item);
      //   yield put(updateUserSuccess(item));
      //   //  history.push(adminRoot);
      // } else {
      //   yield put(updateUserError('No se a podido actualizar el usuario'));
      // }
      // } else {
      //   yield put(updateUserError('No se a podido actualizar el usuario'));
    }
  } catch (error) {
    yield put(updateUserError(error));
  }
}

export function* watchUpdateEmail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_USER, updateUsernameAndPhone);
}

const updateEmailVerificationAsync = async (data) =>
  axios.post(`${servicePath}/users/${data.currentUser.id}`, {
    email: data.user.email,
  });

const updateEmailAsync = async (data) =>
  axios
    .put(`${servicePath}/users/${data.currentUser.id}`, {
      phone: data.user.phone,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

function* updateEmail({ payload }) {
  const { user, currentUser } = payload;
  // console.log(payload);

  // const { history } = payload;
  try {
    const updateEmailItem = yield call(updateEmailAsync, {
      user,
      currentUser,
    });
    if (!updateEmailItem.message) {
      // const newValues = {
      //   ...user,
      //   name: user.name,
      //   lastname: user.lastname,
      //   phone: user.phone,
      // };
      // if (user.phone) {
      //   const updatePhone = yield call(updateUserPhoneAsync, {
      //     user,
      //     currentUser,
      //   });
      //   console.log(updatePhone);
      // if (updatePhone.user.id) {
      //   const item = { id: updatePhone.user.id, ...defaultCurrentUser };
      //   setCurrentUser(item);
      //   yield put(updateUserSuccess(item));
      //   //  history.push(adminRoot);
      // } else {
      //   yield put(updateUserError('No se a podido actualizar el usuario'));
      //   }
      // } else {
      //   yield put(updateUserError('No se a podido actualizar el usuario'));
    }
  } catch (error) {
    yield put(updateUserError(error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchUpdateUser)]);
  yield all([fork(watchAddUserImage)]);
  yield all([fork(watchDeleteUserImage)]);
}
